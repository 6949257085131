
import { Component, Vue } from 'vue-property-decorator'
import OrderPane from '@/components/order/order-pane.vue'
import { apiOrderLists, apiFactoryLists, apiModelList } from '@/api/shop'
import ExportData from '@/components/export-data/index.vue'
import { RequestPaging } from '@/utils/util'
import { OrderType } from '@/utils/type'
@Component({
  components: {
    OrderPane,
    ExportData
  }
})
export default class Order extends Vue {
  // S Data
  activeName: any = 'all_count' // 全部;

  apiOrderLists = apiOrderLists

  tabs = [
    {
      label: '全部',
      name: OrderType[0]
    },
    {
      label: '待发货',
      name: OrderType[1]
    },
    {
      label: '已发货',
      name: OrderType[2]
    }
  ]

  paginations = {
    page_no: 1,
    page_size: 25,
    count: 1
  }

  index = 0

  pager = new RequestPaging()

  tabCount = {
    all_count: 0, // 全部
    delivery_count: 0, // 待发货
    finish_count: 0 // 已发货
  }

  form = {
    copysid_id: '',
    factory_id: ''
  }

  model_lists = []
  factory_lists = []

  otherLists: any = {
    order_terminal_lists: [], // 来源
    order_type_lists: [], // 订单类型
    pay_way_lists: [], // 余额支付
    pay_status_lists: [], // 支付状态
    delivery_type_lists: [] // 配送方式
  }
  // E Data

  // S Methods

  // 获取订单信息
  getOrderLists(page?: number) {
    page && (this.pager.page = page)
    const status: any = this.activeName == 'all_count' ? '' : OrderType[this.activeName]
    this.pager
      .request({
        callback: apiOrderLists,
        params: {
          order_status: status == '' ? '' : status - 1,
          ...this.form
        }
      })
      .then(res => {
        this.tabCount = res?.extend
      })
  }

  getFactoryList() {
    apiFactoryLists({}).then((res: any) => {
      this.factory_lists = res.lists
    })
  }

  getModelList() {
    apiModelList({ ...this.paginations }).then((res: any) => {
      this.model_lists = res.lists
      this.paginations.count = res.count
    })
  }

  // 重置搜索
  reset() {
    Object.keys(this.form).map(key => {
      this.$set(this.form, key, '')
    })
    this.getOrderLists()
  }

  handleSizeChange(val: any) {
    console.log(val)
  }

  handleCurrentChange(val: any) {
    console.log(val)
    this.paginations.page_no = val
    this.getModelList()
  }

  // 获取订单其他 方式数据
  getOtherMethodList() {
    // apiOtherLists().then((res: any) => {
    //   this.otherLists = res
    // })
  }
  // E Methods

  // S  life cycle

  created() {
    // 获取订单信息
    this.getOrderLists()
    this.getFactoryList()
    this.getModelList()
    // 获取其他方式数据
    // this.getOtherMethodList()
  }

  // E life cycle
}
